import protobuf from "protobufjs";
import { decodeProtocolBuffer } from "../decode";
import protoRoot from "../proto-root";
import BatteryStatus from "./BatteryStatus";
import CameraEvent from "./CameraEvent";

function create({ batteryStatus, lastEvent }: Required<CameraStatus>) {
  const Status = protoRoot.lookupType("comm.CameraStationPreview.Status");

  return Status.create({
    lastEvent: CameraEvent.create(lastEvent),
    batteryStatus: BatteryStatus.create(batteryStatus)
  });
}

function encode(payload: Required<CameraStatus>) {
  const previewStatus = create(payload);
  const PreviewStatus = protoRoot.lookupType("comm.CameraStationPreview.Status");

  const buffer = PreviewStatus.encode(previewStatus).finish();
  return protobuf.util.base64.encode(buffer, 0, buffer.length);
}

function decode(base64: string): Required<CameraStatus> {
  const buffer = new Uint8Array(protobuf.util.base64.length(base64));
  protobuf.util.base64.decode(base64, buffer, 0);

  return decodeProtocolBuffer({
    typePath: "comm.CameraStationPreview.Status",
    buffer,
    root: protoRoot
  }) as Required<CameraStatus>;
}

const previewStatus = { create, encode, decode };

export default previewStatus;
