import { wait } from "@/utils";
import { CameraStore, useCamera } from "camera/store/camera";

export async function getCameraAndMicrophoneInfo() {
  try {
    const deviceList = await navigator.mediaDevices.enumerateDevices();

    const videoDevices = deviceList.filter((device) => device.kind === "videoinput");
    const audioDevices = deviceList.filter((device) => device.kind === "audioinput");

    return {
      cameraId: videoDevices[0].deviceId,
      microphoneId: audioDevices[0].deviceId,
      cameraList: videoDevices
    };
  } catch (err) {
    log.err("Couldn't get device list from navigator");
  }
}

export function createDeviceChangeHandler() {
  let { cameraId, microphoneId, cameraList } = useCamera.getState();

  const compareDevices = async () => {
    log.info("'devicechange' fired, comparing devices...");
    const availableDevices = await navigator.mediaDevices.enumerateDevices();
    const availableVideoDevices = availableDevices.filter((device) => device.kind === "videoinput");
    const availableAudioDevices = availableDevices.filter((device) => device.kind === "audioinput");

    const devicesIds = availableDevices.map((device) => device.deviceId);
    const availableDeviceIds = devicesIds.filter((id) => id === cameraId || id === microphoneId);

    if (
      (!cameraId && availableVideoDevices.length > 0) ||
      (!microphoneId && availableAudioDevices.length > 0) ||
      (cameraId && !availableDeviceIds.includes(cameraId)) ||
      (microphoneId && !availableDeviceIds.includes(microphoneId)) ||
      availableVideoDevices.length > (cameraList?.length || 0)
    ) {
      if (isDev) {
        log.warn("Change in available devices detected, restarting in 10 seconds...");
        await wait(10000);
        restartApp();
      } else restartApp();
    }
  };

  const onStoreUpdate = (store: CameraStore) => {
    cameraId = store.cameraId;
    microphoneId = store.microphoneId;
  };

  useCamera.subscribe(onStoreUpdate);
  navigator.mediaDevices.addEventListener("devicechange", compareDevices);
}
