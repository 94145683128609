import { addGuard } from "@/utils";
import { rpcEmitter } from "@/modules/events/emitter";
import { messages, RPCRequests } from "@/modules/communication";
import Xmpp from "@/modules/Xmpp";
import RPC from "@/modules/RPC";
import { getHasPermissionError } from "@/modules/stream/permissions";
import { getMediaStream } from "@/modules/stream/getMediaStream";
import Messenger from "@/modules/Messenger";
import PresenceKeeper from "@/modules/app/Presence";
import CameraRtcManager from "camera/modules/CameraRtcManager";
import { cameraStore } from "camera/store/camera";
import { useApp } from "common/store/app";
import streamQualityUpdater from "../streamQualityUpdater";
import { getCameraAndMicrophoneInfo, createDeviceChangeHandler } from "../media-devices";
import Notifier from "../Notifier";
import Handlers from "./rpcHandlers";
import RpcEventUpdater from "./RpcEventUpdater";

type Providing = {
  changeCamera: MonitoringManager["changeCamera"];
  stopFace: MonitoringManager["stopFace"];
};

const { setError, setCameraList, setCameraId, setMicrophoneId, updateVideoTrack, setActiveFaceJid } = cameraStore();
const {
  StartMonitoring,
  StopMonitoring,
  Ping,
  StartVideoBroadcasting,
  ChangeCamera,
  StartVideoReceiving,
  StopVideoReceiving,
  StartCameraHistoryReplayEvents,
  ReloadCameraHistoryReplayEvents,
  StopCameraHistoryReplayEvents,
  SyncMonitoring
} = RPCRequests;
const handlers = new Handlers();

export default class MonitoringManager {
  constructor(private stationManager: CameraStationManager) {
    this.providing = {
      changeCamera: this.changeCamera,
      stopFace: this.stopFace
    };
  }
  providing = {} as Providing;
  private notifier = new Notifier();
  private xmpp = Xmpp.getInstance();
  private messenger = Messenger.getInstance();
  private rtcManager = new CameraRtcManager();
  private rpc = new RPC();
  // better name?
  private rpcEventUpdater = new RpcEventUpdater(this.messenger);

  init = async () => {
    handlers.init({
      stationManager: this.stationManager,
      rtcManager: this.rtcManager,
      changeCamera: this.changeCamera,
      rpcEventUpdater: this.rpcEventUpdater
    });
    this.rpcEventUpdater.init();

    this.rtcManager.startListening();
    this.rpc.startListening();
    PresenceKeeper.setDefaults();

    this.stationManager.on("battery-change", this.batteryChange);
    rpcEmitter.on(StartMonitoring.name, handlers.startMonitoring);
    rpcEmitter.on(StopMonitoring.name, handlers.stopMonitoring);
    rpcEmitter.on(StartVideoBroadcasting.name, handlers.startVideoBroadcast);
    rpcEmitter.on(Ping.name, handlers.ping);
    rpcEmitter.on(ChangeCamera.name, handlers.changeCamera);
    rpcEmitter.on(StartVideoReceiving.name, handlers.startVideoReceiving);
    rpcEmitter.on(StopVideoReceiving.name, handlers.stopVideoReceiving);
    rpcEmitter.on(StartCameraHistoryReplayEvents.name, handlers.startCameraHistoryReplayEvents);
    rpcEmitter.on(ReloadCameraHistoryReplayEvents.name, handlers.reloadCameraHistoryReplayEvents);
    rpcEmitter.on(StopCameraHistoryReplayEvents.name, handlers.stopCameraHistoryReplayEvents);
    rpcEmitter.on(SyncMonitoring.name, handlers.syncMonitoring);

    const deviceInfo = await getCameraAndMicrophoneInfo();

    if (deviceInfo) {
      setMicrophoneId(deviceInfo.microphoneId);
      setCameraId(deviceInfo.cameraId);
      setCameraList(deviceInfo.cameraList);
    }

    try {
      const mediaStream = await addGuard(() =>
        getMediaStream({
          audio: { deviceId: deviceInfo?.microphoneId },
          video: { deviceId: deviceInfo?.cameraId }
        })
      );
      if ("error" in mediaStream) {
        if (getHasPermissionError(mediaStream.error)) setError("permission_denied");
        else setError("failure");
        return;
      }
      const { result: stream } = mediaStream;
      const hasAudio = stream.getAudioTracks().length > 0;
      const hasVideo = stream.getVideoTracks().length > 0;

      if (hasAudio && hasVideo) {
        await streamQualityUpdater.updateVideoTrackQuality(stream);
        cameraStore().setStream(stream);
      } else {
        if (!hasAudio && !hasVideo) setError("failure");
        else if (!hasAudio) setError("no_audio");
        else if (!hasVideo) setError("no_video");
      }

      this.xmpp.sendPresence("sender", await this.stationManager.getCurrentStatePayload());
    } catch (err: any) {
      log.err("Failed 'init' in MonitoringManager", err);
      useApp.getState().setStatus("FAILURE");
    } finally {
      createDeviceChangeHandler();
    }
  };

  changeCamera = async (cameraId: string, jidToIgnore?: string) => {
    const stream = await getMediaStream({
      video: { deviceId: cameraId },
      audio: false
    });
    const videoTrack = stream.getVideoTracks()[0];
    updateVideoTrack(videoTrack);
    setCameraId(cameraId);

    const message = messages.CameraAndFlashlightState.create({ cameraId });
    this.notifier.sendMessageToConnectedViewers(message, jidToIgnore);
  };

  stopFace = async () => {
    const to = cameraStore().activeFaceJid;
    if (!to) return;

    const message = messages.StopVideoBroadcasting.create();
    this.messenger.sendAsEnvelope({
      to,
      payload: { message }
    });
    setActiveFaceJid(null);
  };

  batteryChange = (batteryStatus: BatteryStatus) => {
    const message = messages.BatteryStatus.create(batteryStatus);
    this.notifier.sendMessageToConnectedViewers(message);
  };

  // destroy = () => {
  //   log.info("destroy");
  //   this.stationManager.off("battery-change", this.batteryChange);
  //   rpcEmitter.off(StartMonitoring.name, handlers.startMonitoring);
  //   rpcEmitter.off(StopMonitoring.name, handlers.stopMonitoring);
  //   rpcEmitter.off(StartVideoBroadcasting.name, handlers.startVideoBroadcast);
  //   rpcEmitter.off(Ping.name, handlers.ping);
  //   rpcEmitter.off(ChangeCamera.name, handlers.changeCamera);
  //   rpcEmitter.off(StartVideoReceiving.name, handlers.startVideoReceiving);
  //   rpcEmitter.off(StopVideoReceiving.name, handlers.stopVideoReceiving);
  //   rpcEmitter.off(
  //     StartCameraHistoryReplayEvents.name,
  //     handlers.startCameraHistoryReplayEvents
  //   );
  //   rpcEmitter.off(
  //     ReloadCameraHistoryReplayEvents.name,
  //     handlers.reloadCameraHistoryReplayEvents
  //   );
  //   rpcEmitter.off(
  //     StopCameraHistoryReplayEvents.name,
  //     handlers.stopCameraHistoryReplayEvents
  //   );
  //   this.rtcManager.destroy();
  //   this.rpc.destroy();
  //   this.xmpp.sendPresence("not_chosen_yet");
  // };
}
