import { useMemo } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { t } from "@/utils";
import ListButton from "@/components/ListButton";
import NavigationBar from "@/components/NavigationBar";
import { MotionStringKeys } from "@/enums/motion";
import { NoiseStringKeys } from "@/enums/noise";
import { useDeleteDeviceFlow } from "@/modules/app/DeviceDeleteFlow";
import DeviceCard from "../../DeviceCard";

import NoiseDetectionIcon from "assets/icons/noise_detection.svg?react";
import MotionIcon from "assets/icons/motion.svg?react";
import ConfigureIcon from "assets/icons/configure.svg?react";
import ReplayIcon from "assets/icons/replay.svg?react";
import useVideoConstraints from "@/modules/stream/useVideoConstraints";
import { VideoQualityStringKeys } from "@/enums/videoQuality";
import { useReplayDuration } from "@/hooks/useReplayDuration";
import { getReplayDurationString } from "@/modules/replay/utils";

export default function Settings({
  push,
  onClose,
  cameraSettings,
  xmppLogin,
  deviceName
}: CSTypes.CameraSettingsStepProps) {
  const { settings, name, color, icon } = cameraSettings;
  const { noiseDetectionEnabled, motionDetectionEnabled } = settings;
  const { quality: videoQualityKey } = useVideoConstraints(settings.videoQuality);
  const { key: replayDuration } = useReplayDuration(settings.replayDuration);
  const openDeleteFlow = useDeleteDeviceFlow((state) => state.open);

  const items: {
    label: string;
    action: Cb;
    icon: any;
    iconFill?: string;
    additionalText?: string;
  }[] = useMemo(
    () => [
      {
        label: t("noise.detection"),
        action: () => push("noise-detection"),
        icon: NoiseDetectionIcon,
        additionalText: noiseDetectionEnabled ? t(NoiseStringKeys[settings.micSensitivity]) : t("motionDetection.off")
      },
      {
        label: t("motionDetection.heading"),
        action: () => push("motion-detection"),
        icon: MotionIcon,
        additionalText: motionDetectionEnabled
          ? t(MotionStringKeys[settings.motionSensitivity])
          : t("motionDetection.off")
      },
      {
        label: t("videoSettings.quality.title"),
        action: () => push("video-quality"),
        icon: ConfigureIcon,
        additionalText: t(VideoQualityStringKeys[videoQualityKey])
      },
      {
        label: t("replayLength"),
        action: () => push("replay-duration"),
        icon: ReplayIcon,
        additionalText: getReplayDurationString(replayDuration, "short")
      }
    ],
    [
      noiseDetectionEnabled,
      settings.micSensitivity,
      settings.motionSensitivity,
      motionDetectionEnabled,
      videoQualityKey,
      replayDuration,
      push
    ]
  );
  return (
    <Box>
      <NavigationBar title={t("camera.settings")} onClose={onClose} />
      <Flex columnGap="24px" wrap="wrap" rowGap="1.5rem">
        <DeviceCard
          icon={icon}
          name={name}
          color={color}
          deviceName={deviceName}
          openDeleteFlow={() =>
            openDeleteFlow({
              device: { role: "camera", jid: xmppLogin, name }
            })
          }
          openCustomize={() => push("customize")}
        />
        {items.map(({ label, action, icon, iconFill, additionalText }) => {
          return (
            <ListButton
              additionalText={additionalText}
              iconFill={iconFill || "primary"}
              icon={icon}
              onClick={action}
              key={label}
              label={label}
              props={{
                width: ["100%", "calc(50% - 12px)"]
              }}
            />
          );
        })}
      </Flex>
    </Box>
  );
}
