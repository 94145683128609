import { SystemStyleFunction } from "@chakra-ui/theme-tools";
import type { SystemStyleObject } from "@chakra-ui/styled-system";
import memoizedGetColor from "@/lib/memoizedGetColor";
import getButtonStateColor from "../utils/getButtonStateColor";
import { ACTIVE_OPACITY, HOVER_OPACITY } from "../utils/constants";
import transparentize from "../utils/transparentize";

const memoizedGet = memoizedGetColor(getButtonStateColor);

const baseStyle: SystemStyleObject = {
  fontSize: ["md", "md", "lg"],
  lineHeight: "4",
  borderRadius: ["24px", "28px", "32px"],
  fontWeight: ["medium", "bold"],
  letterSpacing: ["0.5px", "0.71px", "0.8px"],
  transitionProperty: "common",
  transitionDuration: "normal",
  _focusVisible: {
    boxShadow: "elevation1"
  },
  _disabled: {
    opacity: 0.4,
    cursor: "not-allowed",
    boxShadow: "none"
  },
  _hover: {
    _disabled: {
      bg: "initial"
    }
  }
};

const variantOutline: SystemStyleFunction = (props) => {
  return {
    px: [24, 32, 32],
    py: [12, 20, 24],
    border: "1px solid",
    borderColor: "outline",
    color: "primary",
    bg: "transparent",
    _hover: {
      bg: memoizedGet(props, "primary", HOVER_OPACITY)
    },
    _active: {
      bg: memoizedGet(props, "primary", ACTIVE_OPACITY)
    }
  };
};

const variantText: SystemStyleFunction = (props) => {
  return {
    ...variantOutline(props),
    border: "none",
    fontWeight: [500, 700, 700]
  };
};

const variantFilled: SystemStyleFunction = (props) => {
  return {
    px: [24, 32, 32],
    py: [12, 20, 24],
    color: "on-primary",
    bg: "primary",
    _hover: {
      bg: memoizedGet(props, "on-primary", HOVER_OPACITY, "primary")
    },
    _active: {
      bg: memoizedGet(props, "on-primary", ACTIVE_OPACITY, "primary")
    }
  };
};

const variantLink: SystemStyleObject = {
  fontWeight: 400,
  fontFamily: "body",
  fontSize: ["1rem", "1rem", "1.125rem"],
  lineHeight: ["24px", "24px", "28px"],
  letterSpacing: ["0.5px", "0.5px", "0px"]
};

const variantUnstyled: SystemStyleFunction = (props) => {
  return {
    bg: "none",
    color: "inherit",
    display: "inline",
    lineHeight: "inherit",
    m: 0,
    p: 0,
    _hover: {
      bg: memoizedGet(props, props.color || "on-surface", HOVER_OPACITY, props.bg || "surface")
    },
    _active: {
      bg: memoizedGet(props, props.color || "on-surface", ACTIVE_OPACITY, props.bg || "surface")
    }
  };
};

const variantOutlineFixedSmall: SystemStyleFunction = (props) => {
  return {
    ...variantOutline(props),
    px: 24,
    py: 12
  };
};

const variantFilledFixedSmall: SystemStyleFunction = (props) => {
  return {
    ...variantFilled(props),
    px: 24,
    py: 12
  };
};

const variantTextFixedSmall: SystemStyleFunction = (props) => {
  return {
    ...variantText(props),
    px: 24,
    py: 12,
    fontWeight: 500
  };
};

const variantBlurred: SystemStyleFunction = (props) => {
  const bg = transparentize("neutral-variant.30", 0.7)(props);
  return {
    backdropFilter: "blur(8px)",
    px: "12",
    py: "10",
    color: "neutral.99",
    bg,
    _hover: { bg: memoizedGet(props, "neutral.99", HOVER_OPACITY, bg) },
    _active: { bg: memoizedGet(props, "neutral.99", ACTIVE_OPACITY, bg) }
  };
};

const variants = {
  outline: variantOutline,
  text: variantText,
  filled: variantFilled,
  unstyled: variantUnstyled,
  outlineFixedSmall: variantOutlineFixedSmall,
  filledFixedSmall: variantFilledFixedSmall,
  textFixedSmall: variantTextFixedSmall,
  blurred: variantBlurred,
  link: variantLink,
  custom: {}
};

const defaultProps = {
  variant: "filled"
};

const button = {
  baseStyle,
  variants,
  defaultProps
};

export default button;
