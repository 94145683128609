import { useEffect } from "react";
import { Flex, Heading, Spinner } from "@chakra-ui/react";
import { getIsReady } from "@/store/app/selectors";
import { useControls } from "@/hooks";
import { t } from "@/utils";
import { useApp } from "@/store/app";
import IconButton from "@/components/IconButton";
import { usePairingCode } from "camera/store/pairingCode";
import { useStation } from "camera/store/station";
import { getIsPaired } from "camera/store/station/selectors";
import PairingHelpFlow from "./PairingHelpFlow";

import help_outlined from "assets/icons/help_outlined.svg?react";

export default function PairingCode() {
  const flow = useControls();
  const isPaired = useStation(getIsPaired);
  const isReady = useApp(getIsReady);
  const shouldRender = !isPaired;

  const { pairingCode, isValid } = usePairingCode(({ pairingCode, isValid }) => ({ pairingCode, isValid }));
  const getPairingCode = usePairingCode((state) => state.getPairingCode);

  useEffect(() => {
    if (!shouldRender || !isReady) return;
    if (!isValid()) getPairingCode();
  }, [getPairingCode, isValid, shouldRender, isReady]);

  if (!shouldRender) return null;

  const code = pairingCode ? Array.from(pairingCode.code) : null;

  return (
    <Flex
      position="absolute"
      justify="center"
      align="center"
      direction="column"
      gap="16px"
      left="50%"
      bottom={32}
      transform="translateX(-50%)"
      bg="surface1"
      minW="620px"
      borderRadius="lg"
      py="24"
      px="48"
      zIndex={2}
    >
      <Heading color="on-surface" textAlign="center" fontSize="24px" lineHeight="32px" letterSpacing="0.25px">
        {t("pairing.useCodeToPair")}
      </Heading>
      <IconButton
        icon={help_outlined}
        fill="on-surface"
        bg="surface1"
        props={{ pos: "absolute", right: "5px", top: "5px" }}
        tooltip={{ label: t("buttons.help") }}
        onClick={flow.open}
      />
      <PairingHelpFlow close={flow.close} isOpen={flow.isOpen} />
      <Flex bg="secondary-container" justify="center" align="center" py="1rem" px="1.75rem" borderRadius="40px">
        {code ? (
          code.map((number, i) => {
            return <Number key={i}>{number}</Number>;
          })
        ) : (
          <Spinner />
        )}
      </Flex>
    </Flex>
  );
}

function Number({ children }: { children: any }) {
  return (
    <Heading
      as="h3"
      letterSpacing={["normal", null, "2px"]}
      color="primary"
      sx={{
        ":nth-of-type(3n)": {
          mr: "8px"
        },
        ":last-of-type": {
          mr: "0px"
        }
      }}
    >
      {children}
    </Heading>
  );
}
