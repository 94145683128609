import { useEffect, useCallback, useRef } from "react";
import { useCamera } from "camera/store/camera";
import NoiseDetector from "../noise/NoiseDetector";

export default function useMediaStream({ onStreamLoad }: { onStreamLoad: Cb }) {
  const { stream, setError, setLoading } = useCamera((state) => ({
    stream: state.stream,
    setError: state.setError,
    setLoading: state.setLoading,
  }));
  const currentStreamRef = useRef<MediaStream | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const onVideoLoad = useCallback(() => {
    log.media("Video loaded");
    setLoading(false);
    videoRef.current?.removeEventListener("loadeddata", onVideoLoad);
    onStreamLoad();
  }, [setLoading, onStreamLoad]);

  const updateCurrentStream = useCallback(async () => {
    if (!videoRef.current) {
      setError("no_video_element");
      return;
    }

    if (!currentStreamRef.current) {
      videoRef.current.addEventListener("loadeddata", onVideoLoad);
    }
    currentStreamRef.current = stream;
    videoRef.current.srcObject = stream;
  }, [onVideoLoad, setError, stream]);

  useEffect(() => {
    if (stream && stream !== currentStreamRef.current) {
      updateCurrentStream();
      NoiseDetector.connectStream();
    }
  }, [stream, updateCurrentStream]);

  return { videoRef };
}
