import { nanoid } from "nanoid";
import { dataSyncEmitter } from "@/modules/events/emitter";
import CrashReporter from "@/modules/app/CrashReporter";
import { getReplayDuration } from "@/modules/replay/constraints";
import { useStation } from "camera/store/station";
import DB, { DATE_INDEX } from "./DB";
import { EVENT_DELETE_INTERVAL, MAXIMUM_EVENT_DURATION } from "../events/constants";

export default class LocalEvents {
  private static db: DB | null = null;
  private static interval: NodeJS.Timeout | null = null;
  private static resolvers: { [id: string]: (db: DB) => void } = {};

  private static getDBAsync = async (name: string): Promise<DB> => {
    if (this.db) return this.db;
    return new Promise<DB>((resolve) => {
      const id = nanoid();
      this.resolvers[id] = (db) => {
        resolve(db);
        delete this.resolvers[id];
        log.info(`Resolved DB getter for '${name}'`, this.resolvers);
      };
    });
  };

  private static processResolvers = (db: DB) => {
    Object.values(this.resolvers).forEach((cb) => cb(db));
  };

  static init = async () => {
    const db = new DB();
    await db.init();
    this.db = db;
    this.processResolvers(db);
  };

  static save = async (event: CameraEvent, { shouldAnnounce } = { shouldAnnounce: false }) => {
    const db = await this.getDBAsync("save");
    await db.getWrite<DbCameraEvent>("local-events")({
      ...event,
      finished: event.finished ? 1 : 0
    });

    if (!shouldAnnounce) return;
    if (event.intervalEvent && !event.finished) {
      dataSyncEmitter.emit("local-event-create", event);
    } else if (event.intervalEvent) dataSyncEmitter.emit("local-event-update", event);
  };

  static startRemovingOldEvents = async () => {
    try {
      if (this.interval) return;
      log.events("'startRemovingOldEvents' called");
      await this.deleteOld();
      this.interval = setInterval(this.deleteOld, EVENT_DELETE_INTERVAL);
    } catch (err) {
      CrashReporter.sendException("startRemovingOldEvents failed", { key: "err", extra: err });
      log.err("'startRemovingOldEvents' failed, trying again in 5s...");
      setTimeout(this.startRemovingOldEvents, 5000);
    }
  };

  static getAll = async (): Promise<CameraEvent[]> => {
    const db = await this.getDBAsync("getAll");
    const events = await db.readAll<DbCameraEvent>("local-events");
    log.events("'getAll' result", events);
    return events.sort((a, b) => a.start - b.start).map((e) => ({ ...e, finished: Boolean(e.finished) }));
  };

  static getFirstFound = async (direction?: "oldest" | "newest") => {
    const db = await this.getDBAsync("getFirstFound");
    const objectStore = db.createTransaction("readonly", "local-events");
    const index = objectStore.index(DATE_INDEX);
    const request = index.openCursor(null, direction === "oldest" ? "next" : "prev");

    return new Promise<CameraEvent | null>((resolve) => {
      request.onsuccess = (e) => {
        const cursor = (e.target as IDBRequest<IDBCursorWithValue>).result;
        const event = cursor ? (cursor.value as DbCameraEvent) : null;
        resolve(event ? { ...event, finished: Boolean(event.finished) } : null);
      };
    });
  };

  private static deleteOld = async () => {
    const replayDuration = getReplayDuration(useStation.getState().settings.replayDuration);
    log.events("'deleteOld' called with time", replayDuration);
    const db = await this.getDBAsync("deleteOld");
    const upperBound = Date.now() - replayDuration;
    const range = IDBKeyRange.upperBound(upperBound);

    const index = db.createTransaction("readwrite", "local-events").index(DATE_INDEX);
    const request = index.openCursor(range);

    return new Promise<void>((resolve) => {
      request.onsuccess = (e) => {
        const cursor = (e.target as IDBRequest<IDBCursorWithValue>).result;
        if (cursor) {
          const event = cursor.value as DbCameraEvent;
          if (event.finished === 1) {
            log.events("About to delete local event", event.uniqueId);
            cursor.delete();
          }
          cursor.continue();
        } else resolve();
      };
      request.onerror = (e) => {
        log.err("'deleteOld' failed", e);
        resolve();
      };
    });
  };

  static deleteBrokenEvents = async () => {
    const db = await this.getDBAsync("deleteBrokenEvents");
    const upperBound = Date.now() - MAXIMUM_EVENT_DURATION * 2;
    const range = IDBKeyRange.upperBound(upperBound);
    const index = db.createTransaction("readwrite", "local-events").index(DATE_INDEX);
    const request = index.openCursor(range);

    return new Promise<void>((resolve) => {
      request.onsuccess = (e) => {
        const cursor = (e.target as IDBRequest<IDBCursorWithValue>).result;
        if (cursor) {
          const event = cursor.value as DbCameraEvent;
          if (event.finished === 0) {
            log.events("About to delete broken local event", event.uniqueId);
            CrashReporter.sendMessage(`Found broken event ${event.uniqueId} - ${event.start} - ${event.end}`);
            cursor.delete();
          }
          cursor.continue();
        } else resolve();
      };
      request.onerror = (e) => {
        log.err("'deleteOld' failed", e);
        resolve();
      };
    });
  };

  static clearAll = async () => {
    const db = await this.getDBAsync("clearAll");
    await db.clearAll("local-events");
  };
}
