import CameraStationPreview from "@/modules/communication/private/CameraStationPreview";
import { dataSyncEmitter } from "@/modules/events/emitter";
import Xmpp from "@/modules/Xmpp";
import EventEmitter from "@/lib/EventEmitter";
import { useStation } from "camera/store/station";
import { getIsPaired } from "camera/store/station/selectors";
import EventManager from "camera/modules/events/EventManager";
import LocalEvents from "camera/modules/LocalEvents";
import BatteryUpdater from "./BatteryUpdater";
import PreviewUpdater from "./PreviewUpdater";

export default class StationManager {
  status: "idle" | "running" = "idle";
  private get canStart() {
    return getIsPaired(useStation.getState());
  }
  private xmpp = Xmpp.getInstance();
  private batteryUpdater: BatteryUpdater;
  private previewUpdater: PreviewUpdater;
  private emitter = new EventEmitter();
  on = this.emitter.on;
  off = this.emitter.off;

  constructor() {
    this.batteryUpdater = new BatteryUpdater(this.notifyViewers, this.emitter);
    this.previewUpdater = new PreviewUpdater(this.notifyViewers);
  }

  init = () => {
    useStation.subscribe(this.handleStoreUpdate);
    if (this.canStart) {
      log.media("camera paired, about to start camera updater");
      this.start();
    } else {
      log.media("camera not paired, not starting camera updater");
    }
  };

  private start = async () => {
    log.media("starting camera updater");
    this.status = "running";
    await this.previewUpdater.uploadVideoPreview();
    this.previewUpdater.startTakingVideoPreviews();
    this.batteryUpdater.startListeningForChanges();
    dataSyncEmitter.on("event-state-change", this.handleEvent);
  };

  private stop = () => {
    log.media("stopping camera updater");
    this.status = "idle";
    this.previewUpdater.stopTakingVideoPreviews();
    this.batteryUpdater.stopListeningForChanges();
    dataSyncEmitter.off("event-state-change", this.handleEvent);
  };

  private handleEvent = async (e: CameraEvent) => this.notifyViewers(e);

  private handleStoreUpdate = (store: StationStore) => {
    const isPaired = getIsPaired(store);

    if (isPaired && this.status === "idle") this.start();
    if (!isPaired && this.status === "running") this.stop();
  };

  private notifyViewers = async (event?: CameraEvent) => {
    const payload = await this.getCurrentStatePayload(event);
    log.xmpp("Sending presence payload update", payload);
    this.xmpp.sendPresence(undefined, payload);
  };

  getCurrentStatePayload = async (event?: CameraEvent) => {
    const message = CameraStationPreview.encode(await this.getCurrentState(event));
    const payload: CameraCurrentStatePayload = { currentState: message };
    return payload;
  };

  getBatteryStatus = () => this.batteryUpdater.getBatteryStatePayload();

  getCurrentState = async (event?: CameraEvent): Promise<CameraStationPreview> => ({
    status: {
      lastEvent: event || EventManager.getLastEvent() || (await LocalEvents.getFirstFound()) || ({} as any),
      batteryStatus: this.getBatteryStatus()
    },
    image: this.previewUpdater.getPreviewImagePayload()
  });
}
