import { nanoid } from "nanoid";
import { isEmptyObject } from "@/utils";
import { pendingEventsStore } from "camera/store/pendingEvents";
import { useDevices } from "@/store/devices";
import { getIsPaired } from "camera/store/station/selectors";
import { useStation } from "camera/store/station";
import { dataSyncEmitter } from "@/modules/events/emitter";
import { MediaUploadManager } from "../upload/MediaUploadManager";
import LocalEvents from "../LocalEvents";
import CrashReporter from "@/modules/app/CrashReporter";
import { MAXIMUM_EVENT_DURATION, MINIMUM_EVENT_DURATION } from "./constants";

const { addEvent: addEventToUpload, updateUnfinishedEvent } = pendingEventsStore();
const UPDATE_INTERVAL = 5000;

export default class EventManager {
  private static lastEvent: CameraEvent | null = null;
  private static currentEvents: { [id: string]: CameraEvent } = {};
  private static durationUpdaters: { [id: string]: NodeJS.Timeout } = {};
  private static durationLimiters: { [id: string]: NodeJS.Timeout } = {};
  private static canProcess = (() => {
    const isPaired = getIsPaired(useStation.getState());
    useStation.subscribe((store: StationStore) => {
      this.canProcess = getIsPaired(store);
    });
    return isPaired;
  })();

  static triggerEvent = (type: OneTimeEvent, jid: string) => {
    if (!this.canProcess) return;
    const device = useDevices.getState().devices[jid];
    const time = new Date().getTime();
    const currentEvent: CameraEvent = {
      uniqueId: nanoid(),
      type,
      finished: true,
      start: time,
      end: time,
      additionalData: { deviceName: device?.name || "unknown" },
      intervalEvent: false
    };
    log.events("Triggered event", type, currentEvent.uniqueId);
    addEventToUpload(currentEvent);
    LocalEvents.save(currentEvent, { shouldAnnounce: true });
  };

  static startEvent = (type: RepeatedTimeEvent) => {
    if (!this.canProcess) {
      log.events("Not starting event, camera is not paired");
      return;
    }
    const time = new Date().getTime();
    const currentEvent: CameraEvent = {
      uniqueId: nanoid(),
      type,
      finished: false,
      start: time,
      end: time + MINIMUM_EVENT_DURATION,
      additionalData: {},
      intervalEvent: true
    };
    this.checkForAndfinishBadEvent(currentEvent.type);

    log.events("Starting event", type, currentEvent.uniqueId, new Date(currentEvent.start).toLocaleTimeString());
    if (isEmptyObject(this.currentEvents)) MediaUploadManager.startMarkingDataForUpload();
    if (!this.currentEvents[currentEvent.uniqueId]) this.currentEvents[currentEvent.uniqueId] = currentEvent;

    addEventToUpload({ ...currentEvent });
    LocalEvents.save(currentEvent, { shouldAnnounce: true });

    this.lastEvent = currentEvent;
    this.createEventDurationUpdater(currentEvent.uniqueId);
    this.createEventDurationLimiter(currentEvent.uniqueId);
    dataSyncEmitter.emit("event-state-change", currentEvent);
    return currentEvent.uniqueId;
  };

  static endEvent = (eventId: string) => {
    if (!this.canProcess) return;
    const currentEvent = this.currentEvents[eventId];
    if (!currentEvent) {
      log.warn("Trying to call 'endEvent' for event that does not exist", eventId);
      return;
    }
    log.events("Ending event", currentEvent.type, currentEvent.uniqueId);
    const now = new Date().getTime();
    this.deleteEventDurationUpdater(eventId);
    this.deleteEventDurationLimiter(eventId);

    if (now > currentEvent.end) currentEvent.end = now;
    currentEvent.finished = true;
    dataSyncEmitter.emit("event-state-change", currentEvent);

    addEventToUpload({ ...currentEvent });
    LocalEvents.save(currentEvent, { shouldAnnounce: true });
    delete this.currentEvents[eventId];

    if (isEmptyObject(this.currentEvents)) MediaUploadManager.stopMarkingDataForUpload();
  };

  static getLastEvent = () => this.lastEvent;

  private static createEventDurationUpdater = (id: string) => {
    if (this.durationUpdaters[id]) {
      log.warn(`Updater for event ${id} already exists`);
      return;
    }
    this.durationUpdaters[id] = setInterval(() => {
      this.currentEvents[id].end = new Date().getTime();
      updateUnfinishedEvent(this.currentEvents[id]);
    }, UPDATE_INTERVAL);
  };

  private static deleteEventDurationUpdater = (id: string) => {
    if (this.durationUpdaters[id]) {
      clearInterval(this.durationUpdaters[id]);
      delete this.durationUpdaters[id];
    } else log.warn("Trying to clear nonexisting updater", id);
  };

  private static createEventDurationLimiter = (id: string) => {
    if (this.durationLimiters[id]) {
      log.warn(`Limiter for event ${id} already exists`);
      return;
    }
    this.durationLimiters[id] = setTimeout(() => {
      dataSyncEmitter.emit("event-maximum-duration-reached", id);
      clearTimeout(this.durationLimiters[id]);
      delete this.durationLimiters[id];
    }, MAXIMUM_EVENT_DURATION);
  };

  private static deleteEventDurationLimiter = (id: string) => {
    if (this.durationLimiters[id]) {
      clearTimeout(this.durationLimiters[id]);
      delete this.durationLimiters[id];
    } else log.warn("Trying to clear nonexisting limiter", id);
  };

  private static checkForAndfinishBadEvent = (eventType: CameraEvent["type"]) => {
    const badEvent = Object.values(this.currentEvents).find((e) => e.type === eventType);
    if (badEvent) {
      CrashReporter.sendMessage(`Found bad ${eventType} event`);
      log.warn(
        `Found bad ${eventType} event, started at ${new Date(badEvent.start).toLocaleTimeString()}, will try to end ${
          badEvent.uniqueId
        }`
      );
      this.endEvent(badEvent.uniqueId);
    }
  };

  static reset = () => {
    this.lastEvent = null;
    this.currentEvents = {};
    Object.values(this.durationUpdaters).forEach((u) => clearInterval(u));
    Object.values(this.durationLimiters).forEach((u) => clearTimeout(u));
    this.durationUpdaters = {};
    this.durationLimiters = {};
  };
}
