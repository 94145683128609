import { create } from "zustand";
import { persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { useEventFilters } from "viewer/store/eventFilters";
import environment from "@/modules/environment";

const initialState: DevicesState = {
  devices: {}
};

const useDevices = create<DevicesStore>()(
  persist(
    immer((set, get) => ({
      ...initialState,
      updatePairedDevice(device) {
        log.devices("updating device", device);
        if (!device.jid) return;
        set(({ devices }) => {
          const updatedDevice = {
            ...(devices[device.jid] || {}),
            ...device
          };
          if (device.settings)
            (updatedDevice as StoreCameraDevice).settings = {
              ...(devices as StoreCameraDevices)[device.jid]?.settings,
              ...device.settings
            };

          devices[device.jid] = updatedDevice as StoreDevice;
        });
      },
      updateExistingDevice(device) {
        if (!device.jid) return;
        const doesExist = device.jid in get().devices;
        if (!doesExist) {
          log.err("Trying to update nonexisting device", device);
          return;
        }
        log.devices("updating existing device", device);
        set(({ devices }) => {
          const updatedDevice = {
            ...(devices[device.jid] || {}),
            ...device
          };
          if (device.settings)
            (updatedDevice as StoreCameraDevice).settings = {
              ...(devices as StoreCameraDevices)[device.jid]?.settings,
              ...device.settings
            };

          devices[device.jid] = updatedDevice as StoreDevice;
        });
      },
      removePairedDevice(jid) {
        log.devices("removing device", jid);
        useEventFilters.getState().removeCameraFilter(get().devices[jid].objectId);
        set(({ devices }) => {
          delete devices[jid];
        });
      },
      resetState() {
        log.devices("resetting devices store");
        set(initialState);
      }
    })),
    {
      name: environment.appMode + "-devices"
    }
  )
);

export { useDevices };
